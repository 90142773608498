
.rect{
    margin: 8% 7%;
    padding: 20px 50px;
    background-color: aliceblue;
    border-radius: 15px;
}
.rect p{
    margin-left: 10px;
    font-size: 25px;
    font-weight: 500;
    color: rgb(110, 110, 110);
}