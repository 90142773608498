.navbar {
    background: rgb(250, 250, 255);
    box-shadow: 0 4px 6px rgba(22, 22, 26, 0.18);
    
  }
  .navbar-brand{
      color: #000 !important;
      
  }
  .navbar-brand:hover{
    color: #000 !important;
  }
  
  .navbar .navbar-nav .nav-link {
    color: #000 !important;
    font-size: 15px;
    font-weight: 400;
  }
  .btn-outline-dark {
    border-radius: 0;
  }
  .btn-outline-dark:focus {
    box-shadow: none;
  }

  @media screen and (max-width: 768px) {
    .btn-outline-dark {
      border: none;
      padding: 0;
      outline: none;
    }
  
    .btn-outline-dark:hover {
      background: none;
      color: rgb(0, 0, 0);
    }
  }
  .nav-item{
    transition: all 0.2s ease-in-out;
  }
  .nav-item:hover{
    cursor: pointer;
    transform: translate(0px,-2px);

  }

 
  