.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin: 50px 150px;
}

/* .grid-item { 
    background-color: rgb(248, 250, 255); 
    display: grid; 
    grid-template-columns: 1fr 2fr; 
    border-radius: 10px;
} */

.grid-item {
  background-color: rgb(248, 250, 255);
  display: flex;
  /* align-items: center;  */
  /* justify-content: space-around;  */
  border-radius: 10px;
  height: 220px;
}
.grid-item:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.item img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

.title {
  font-size: x-large;
  font-weight: 800;
}

.info h3 {
  margin-top: 20px;
  font-weight: bold;
}

p {
  margin: 0 !important;
}

.info {
  padding: 0 30px;
  font-family: "Times New Roman", Times, serif;
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.item {
  float: left;
}

.d-flex {
  width: 250px;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.item1 h2 {
  margin-left: 65%;
}

.item2 {
  margin-top: 5px;
}

body {
  background: rgb(255, 255, 255);
}

.btn {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  font-weight: bold;
}
.btn:hover {
  color: white;
  background-color: black;
  border: 1px solid black;
  font-weight: bold;
}
