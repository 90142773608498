.res-card{
    font-family: 'Times New Roman', Times, serif;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    background-color: rgb(248, 250, 255);
   
}
.res-card:hover{
    cursor: pointer;
    transform: scale(1.1);
    
}

  
  
.res-logo{
    /* background-image: url("C:\Users\850066447\OneDrive - Genpact\Desktop\Realestate-proj\RealEstate3\public\ais.jpg"); */
    width: 100%;
    border-radius: 10px;
   
    height: 220px ;
}
.details{

    text-align: left;
    margin-top: 20px;
}
.details p{
    font-family: 'Times New Roman', Times, serif;
    margin: 2px;
    color: rgb(86, 86, 86);
    font-size: 20px;
    font-weight: 500;
}

.expanded .expanded-details {
    visibility: visible;
    opacity: 1;
  }
  
  .expanded-details {
    visibility: hidden;
    opacity: 0;
    background-color: #ffffff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }
  
  .res-card:hover .expanded-details {
    visibility: visible;
    opacity: 1;
  }