
.main{
    position: relative;
    margin-top: 50px;
    min-height: 100vh;
    top: 0;
    left: 0px;
    margin-left: 300px !important;
    transition: all 0.5s ease;
    z-index: 2;
    margin-right: 150px;

}

