.confirmed{
    width :40%;
    height: auto;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    
}
.qwer{
    text-align: center;
}